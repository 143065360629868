<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-text-field
            v-model="vm.reportCompletedBy"
            label="Report completed by"
            v-validate="'required'"
            data-vv-as="Report completed by"
            name="reportCompletedBy"
            :error-messages="errors.collect('reportCompletedBy')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="vm.providerName"
            label="Provider Name"
            v-validate="'required'"
            data-vv-as="Provider Name"
            name="providerName"
            :error-messages="errors.collect('providerName')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.providerRegistrationId"
            label="Provider Registration ID"
            v-validate="'required'"
            data-vv-as="Provider Registration ID"
            name="providerRegistrationId"
            :error-messages="errors.collect('providerRegistrationId')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.providerABN"
            label="Provider ABN"
            v-validate="'required'"
            data-vv-as="Provider ABN"
            name="providerABN"
            :error-messages="errors.collect('providerABN')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.registrationGroup"
            label="Registration Group"
            v-validate="'required'"
            data-vv-as="Registration Group"
            name="registrationGroup"
            :error-messages="errors.collect('registrationGroup')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.state"
            label="State"
            v-validate="'required'"
            data-vv-as="State"
            name="state"
            :error-messages="errors.collect('state')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.reportCompletedBy"
            label="Report Completed By"
            v-validate="'required'"
            data-vv-as="Report Completed By"
            name="reportCompletedBy"
            :error-messages="errors.collect('reportCompletedBy')"
          ></v-text-field>
        </v-col>

        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "provider-details",

  data: () => ({
    vm: {},
    form: {}
  }),
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.providerDetails == undefined
          ? {}
          : this.currentIncident.providerDetails;
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.providerDetails = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

